/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteJWTCookie, redirectToLogin, redirectToMaintenanceMode } from '_auth';
import { getEnv } from '_config';

import { ApiError, doFetch } from './config';
import type { ReadEndpoint, MutateEndpoint, GetOptions, MutateOptions, SimulatorGetter } from './types';

export const makeHttpGet = (tokenGetter: () => string, getSimulator: SimulatorGetter) => <T>(
    endpoint: ReadEndpoint<T>,
    options: GetOptions<T> = {}
) => {
    const env = getEnv();
    const envOptions = {
        useSimulator: !env.isConnected,
        apiDomain: env.baseApi,
        token: tokenGetter()
    };

    const { onError, params, ...rest } = options;

    const result = useQuery<T>({
        queryKey: endpoint.key,
        queryFn: () => doFetch(
            endpoint,
            params ?? {},
            envOptions,
            getSimulator
        ),
        onError: async (error: unknown) => {
            if (error instanceof ApiError) {
                onError?.(error);

                if (error.status === 401) {
                    await deleteJWTCookie();
                    redirectToLogin();
                }

                if (error.status === 503) {
                    redirectToMaintenanceMode();
                }
            }
        },
        ...rest
    });

    return result;
};

export const makeHttpMutate = (tokenGetter: () => string, getSimulator: SimulatorGetter) => (
    endpoint: MutateEndpoint,
    options?: MutateOptions,
) => {
    const env = getEnv();
    const envOptions = {
        useSimulator: !env.isConnected,
        apiDomain: env.baseApi,
        token: tokenGetter()
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queryClient = useQueryClient();

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = useMutation({
        mutationKey: endpoint.key,
        mutationFn: (params: any) => doFetch(endpoint, params, envOptions, getSimulator),
        onSuccess: (data, variables) => options?.onSuccess?.(data, variables, queryClient)
    });

    return result;
};
