import Cookies from 'universal-cookie';
import { log } from '_utils/logs';
import { getEnv } from '_config';
import { getCurrentDomain } from '_utils/url';

export const setJWTCookie = (token: string) => {
    const cookies = new Cookies();
    const domain = getCurrentDomain();
    const { cookieName } = getEnv();
    cookies.set(cookieName, token, {
        domain,
        path: '/',
        maxAge: 2629746 // 1 month
    });
    log('[Cookie] Set Auth Token:', token, domain);
};

export const getJWTFromCookie = () => {
    const cookies = new Cookies();
    const { cookieName } = getEnv();
    const jwt = cookies.get(cookieName);
    log('[Cookie] Get Auth Token:', jwt);

    return jwt;
};

export const deleteJWTCookie = async () => new Promise(resolve => {
    const cookies = new Cookies();
    cookies.addChangeListener(() => {
        log('[Cookie] Auth Token removed');
        resolve(true);
    });
    const { cookieName } = getEnv();
    cookies.remove(cookieName, { domain: getCurrentDomain(), path: '/' });
    cookies.remove(cookieName);
});
