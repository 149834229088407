import type { MutateEndpoint, ReadEndpoint } from '_api';

import type { User } from '../global/types/objects.types';
import mapper from './mappers';

export const getProfile: ReadEndpoint<User> = {
    key: ['profile'],
    method: 'GET',
    path: '/api/v3/profile',
    formatResponse: json => mapper.from(json),
    simulator: { filepath: 'profile.simulateUserData' }
};

export const updateProfile: MutateEndpoint = {
    key: ['updateProfile'],
    method: 'PUT',
    path: '/api/v3/profile',
    formatParams: user => ({ user: mapper.to(user) })
};
