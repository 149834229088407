import type { Mapper } from '_api';
import { isStudentByRoles } from '_config';

import type { User } from '../global/types/objects.types';

const mapper: Mapper<User> = {
    from: user => ({
        id: String(user.id),
        idNumber: user.id,
        firstName: user.firstname,
        lastName: user.lastname,
        fullName: `${user.firstname} ${user.lastname}`,
        email: user.email,
        picture: user.photo_url || `https://www.gravatar.com/avatar/${user.id}?d=monsterid&f=y`,
        isMasquerading: user.masquerading,
        isStaff: !isStudentByRoles(user.roles),
        isValidated: user.has_validated_email ?? false,
    }),

    to: (user: User) => ({
        firstname: user.firstName,
        lastname: user.lastName
    })
};

export default mapper;
