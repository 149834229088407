export const getSimulator = async path => {
    if (!path) return null;
    const [folder, fn] = path.split('.');

    // vite build does not handle dynamic import with a path variable like shared/folder
    // see https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#globs-only-go-one-level-deep
    // specify shared explicitly is needed
    const module = await (() => {
        if (folder.includes('shared')) {
            const [, sharedFolder] = folder.split('/');
            return import(`../../shared/${sharedFolder}/simulators.js`);
        }
        return import(`../../${folder}/simulators.js`);
    })();
    return module[fn];
};
