import { memo, Suspense, lazy } from 'react';
import { createBrowserHistory } from 'history';

import { useUserAuth } from '_auth';

import { useSignedInUser, useUserTokenFromStore } from './hooks/user';
import { useTokenStoreSet } from './stores/user';

const ThemeProvider = lazy(() => import('./ThemeProvider'));

const history = createBrowserHistory();
const cleanTokenInUrl = () => {
    history.replace({ search: '' });
};

const Auth = () => {
    const setUserToken = useTokenStoreSet();
    useUserAuth({
        cleanTokenInUrl,
        storeToken: setUserToken
    });

    return <UserProviderWrapper />;
};

const UserProviderWrapper = () => {
    const _userTokenFromStore = useUserTokenFromStore();

    if (!_userTokenFromStore) return null;
    return <UserProvider />;
};

const UserProvider = () => {
    // Loading signed-in user profile
    const { isLoading } = useSignedInUser();

    if (isLoading) return null;

    return (
        <Suspense>
            <ThemeProvider />
        </Suspense>
    );

};

export default memo(Auth);
