/* eslint-disable camelcase */
import { useEffect, useState } from 'react';

import { log } from '_utils/logs';
import { getEnv } from '_config';

import { deleteJWTCookie, getJWTFromCookie, setJWTCookie } from './cookies';

type GetTokenInput = {
    refresh_token?: string;
    token?: string;
}

const getHeaders = (token: string | undefined) => Object.assign(
    {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    token ? { 'Authorization': `Bearer ${token}` } : null
);

const _callApi = async ({ endpoint, params, method = 'POST' }: {
    endpoint: string,
    method?: string,
    params?: GetTokenInput
}) => {

    const { baseApi, isDev, isConnected } = getEnv();

    const body = JSON.stringify(params);

    if (isDev && !isConnected) {
        log('[API] Call:', { endpoint, params, method, body });
        return params?.refresh_token;
    }

    const options = {
        mode: 'cors' as RequestMode,
        method,
        headers: getHeaders(params?.token),
        body
    };

    try {
        const res = await fetch(`${baseApi}/${endpoint}`, options);
        // Status error
        if (res.status >= 400) {
            // eslint-disable-next-line no-console
            console.error(res);
            return false;
        }

        if (res.status === 201 || res.status === 204) return true;

        const json = await res.json();
        return json.token;
    }
    catch (e: unknown) {
        throw new Error(e as string);
    }
};

export const redirectToLogin = () => {
    const currentDomain = window.location.host;
    const currentPath = window.location.pathname;
    const path = currentPath.endsWith('/') ? '' : `&path=${currentPath}`;
    const { loginUrl } = getEnv();
    return window.location.replace(`${loginUrl}?domain=${currentDomain}${path}`);
};

export const redirectToMaintenanceMode = () => {
    const { odysseyUrl } = getEnv();
    return window.location.replace(odysseyUrl);
};

type UseUserTokenInput = {
    cleanTokenInUrl: () => void;
    storeToken: (token: string) => void;
    devToken?: string
}
export const useUserAuth = ({ cleanTokenInUrl, storeToken, devToken }: UseUserTokenInput): boolean => {

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            const cookieToken = getJWTFromCookie();
            if (cookieToken) {
                storeToken(cookieToken);
                setLoading(false);
                return;
            }

            const { isDev } = getEnv();
            const checkTokenValidity = async (token: string) => {
                const res = await _callApi({
                    endpoint: 'api/v3/tokens/exchange',
                    params: { refresh_token: token }
                });

                if (res.error) {
                    redirectToLogin();
                    return;
                }

                return res as string;
            };

            const searchParams = new URLSearchParams(window.location.search);
            const tempToken = searchParams.get('token');
            if (!tempToken) {
                // If dev, return a token
                if (isDev) {
                    const token = devToken ?? 'my_super_fake_long_life_token';
                    setJWTCookie(token);
                    storeToken(token);
                    setLoading(false);
                    return;
                }
                return redirectToLogin();
            }

            const token = await checkTokenValidity(tempToken);
            if (!token) return redirectToLogin();

            setJWTCookie(token);
            storeToken(token);
            cleanTokenInUrl();
            setLoading(false);
        })();
    }, [cleanTokenInUrl, devToken, storeToken]);

    return loading;
};

export const doLogout = async params => {
    const token = getJWTFromCookie();
    await deleteJWTCookie();
    await _callApi({
        endpoint: 'api/v3/tokens',
        method: 'DELETE',
        params: { token }
    });
    if (params?.reload) window.location.reload();
    else redirectToLogin();
};
