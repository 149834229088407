
import type { AsyncTrainingType, BootcampTrainingType } from '.';

export const ASYNC_TRAINING_TYPES: { [key: string]: AsyncTrainingType } = {
    SKILL_COURSE: 'skill_course',
    DISCOVERY_COURSE: 'discovery_course'
};

export const BOOTCAMP_TRAINING_TYPES: { [key: string]: BootcampTrainingType } = {
    BOOTCAMP: 'bootcamp',
    WORK_AND_STUDY: 'work_and_study'
};

export const ASYNC_TRAINING_TYPES_VALUES = Object.values(ASYNC_TRAINING_TYPES);

export const BOOTCAMP_TRAINING_TYPES_VALUES = Object.values(BOOTCAMP_TRAINING_TYPES);
