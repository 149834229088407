/* eslint-disable @typescript-eslint/no-explicit-any */
import { log } from '_console';

import type { Endpoint, SimulatorGetter } from './types';

type EnvOptions = {
    useSimulator: boolean;
    apiDomain: string;
    token?: string;
};

const sleep = (timer: number) => new Promise(resolve => setTimeout(resolve, timer));

const responseFromSimulator = async <T>(endpoint: Endpoint<T>, getSimulator: SimulatorGetter): Promise<T> => {
    const { simulator, formatResponse, path, method } = endpoint;
    log('[_API] Call:', { path, method, params: simulator?.params });

    if (!simulator)
        throw new Error(`missing simulator for ${endpoint.path}`);

    await sleep(simulator.timer ?? 350);
    const fn = await getSimulator(simulator.filepath);
    const data = fn?.({ ...simulator.params });

    log('[_API] Success:', { path, data, mapped: formatResponse?.(data) ?? data });
    return formatResponse?.(data) ?? data;
};

type ErrorType = {
    status: number;
    statusText?: string;
    message?: string;
}

export class ApiError extends Error implements ErrorType {
    status: number;
    statusText?: string;

    constructor(status: number, statusText?: string, message?: string) {
        super(message);

        this.status = status;
        this.statusText = statusText;
    }
}

export const doFetch = async <T>(
    endpoint: Endpoint<T>,
    rawParams: any,
    options: EnvOptions,
    getSimulator: SimulatorGetter
): Promise<T> => {
    if (options.useSimulator) return responseFromSimulator(endpoint, getSimulator);

    const { method, path, formatResponse, formatParams, withCredentials, acceptType } = endpoint;
    const params = formatParams?.(rawParams) ?? rawParams;

    let url = `${options.apiDomain}${path}`;
    if (method === 'GET' && Object.keys(params).length > 0)
        url = url.concat(new URLSearchParams(params).toString());

    const opts = Object.assign({},
        {
            mode: 'cors',
            method,
            headers: {
                'Accept': acceptType ?? 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${options?.token}`
            }
        },
        withCredentials && { credentials: 'include' },
        method !== 'GET' && Object.keys(params).length > 0 && { body: JSON.stringify(params) }
    );

    const result = await fetch(url, opts as RequestInit);

    // 204-No Content
    if (result.status === 204) return {} as T;
    // Status error
    if (result.status >= 400)
        throw new ApiError(result.status, result.statusText, await result.text());

    const json = await result.json();
    return formatResponse?.(json) ?? json;
};
