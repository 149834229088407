export const PROGRESS_STATES = {
    NONE: '',
    STARTED: 'started',
    INTRO_COMPLETED: 'introduction_completed',
    LESSON_COMPLETED: 'lesson_completed',
    EXERCISE_COMPLETED: 'exercise_completed',
    SUMMARY_COMPLETED: 'summary_completed',
    PROJECT_COMPLETED: 'project_completed',
    QUIZ_COMPLETED: 'quiz_completed',
    COMPLETED: 'internal::completed',
    CERTIFICATION_REQUESTED: 'certification_requested',
};

export const PROGRESS_STATES_WEIGHT = {
    [PROGRESS_STATES.NONE]: 0,
    [PROGRESS_STATES.STARTED]: 1,
    [PROGRESS_STATES.INTRO_COMPLETED]: 5,
    [PROGRESS_STATES.LESSON_COMPLETED]: 6,
    [PROGRESS_STATES.EXERCISE_COMPLETED]: 10,
    [PROGRESS_STATES.SUMMARY_COMPLETED]: 15,
    [PROGRESS_STATES.PROJECT_COMPLETED]: 20,
    [PROGRESS_STATES.QUIZ_COMPLETED]: 25,
    [PROGRESS_STATES.COMPLETED]: 50,
};

export const PROGRESS_OBJECTS = {
    TRAINING: 'program',
    MODULE: 'course',
    NOTION: 'notion'
};
