import ReactDOM from 'react-dom/client';
import Root from './Root';
import { loadEnv } from './global/config/env';
import { initTracker } from './global/config/tracking';

const init = async () => {
    await loadEnv();
    initTracker();
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );
    root.render(
        <Root />
    );

};

init();
