import { getEnv } from '_config';

export const log = (...data: unknown[]) => {
    const env = getEnv();
    if (env.debug) console.log(...data); //eslint-disable-line
};
export const warn = (...data: unknown[]) => {
    const env = getEnv();
    if (env.debug) console.warn(...data); //eslint-disable-line
};
export const error = (...data: unknown[]) => {
    const env = getEnv();
    if (env.debug) console.error(...data); //eslint-disable-line
};
