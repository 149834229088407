import { lazy, Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CssBaseline } from '@mui/material';
import { Helmet } from 'react-helmet';

import { useEnv } from './global/hooks/env';

import Auth from './global/UserProvider';

const JotailDevTools = lazy(() => import('./global/JotaiDevTools'));

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

const Providers = () => {

    const env = useEnv();
    const { ascii, universe, isDev, isProd, schoolName } = env;

    const getUniverseAssetUrl = asset => `/${universe}/${asset}`;

    useEffect(() => {
        if (!ascii) return;
        /* ASCII ART */
        // eslint-disable-next-line
        console.log(`%c
        ${ascii.code}
`, `color: ${ascii.color}; font-family: Monospace`);
    }, [ascii]);

    return (<>
        <Helmet>
            <link rel="apple-touch-icon" sizes="180x180" href={getUniverseAssetUrl('apple-touch-icon.png')} />
            <link rel="icon" type="image/png" sizes="32x32" href={getUniverseAssetUrl('favicon-32x32.png')} />
            <link rel="icon" type="image/png" sizes="16x16" href={getUniverseAssetUrl('favicon-16x16.png')} />
            <link rel="icon" href={getUniverseAssetUrl('favicon.ico')} />
            <link rel="manifest" href={getUniverseAssetUrl(`${isProd ? 'web' : 'staging'}.webmanifest`)} />
            <meta name="description" content={`Async by ${schoolName}`} />
            <title>Async by {schoolName}</title>
        </Helmet>
        <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={{ className: 'rqdt-button' }} />
            {isDev && (
                <Suspense>
                    <JotailDevTools />
                </Suspense>
            )}
            <Auth />
        </QueryClientProvider>
    </>);
};

export default Providers;

