import isURL from 'validator/es/lib/isURL';

export const stripProtocol = (url: string) => url.replace('https://', '').replace('http://', '');

export const stripProtocolAndPort = (url: string) => url.replace('https://', '').replace('http://', '').replace(/:\d{1,}/, '');

export const getDomain = (url: string) => {
    if (url.includes('appspot.com')) return url;

    const [, ...domain] = url.split('.');
    return domain.join('.');
};

export const getPrintableDomain = (url: string) => getDomain(url).replace('/', '');

export const getCurrentDomain = () => getDomain(window.location.hostname);

export const isAllowedVideoUrl = (url: string) => {

    const isValidUrl = isURL(url || '');
    if (!isValidUrl) return null;

    const googleDriveURLDetection = (googleUrl: string) => {
        if (googleUrl.includes('/folders/')) return 'folder';
        if (googleUrl.includes('open?id=')) return 'previewLink';
        if (googleUrl.includes('/file/d/')) return 'directFile';
    };

    const { host } = new URL(url);
    if (host.includes('youtube') || host.includes('youtu.be')) return 'youtube';
    if (host.includes('drive.google.com')) return googleDriveURLDetection(url);
    if (host.includes('tokbox.com')) return 'vonage';
    return false;
};
