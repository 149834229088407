export const ACCENT_COLORS = {
    BLUE: 'blue',
};

const ACCENT_COLORS_DATA = {
    [ACCENT_COLORS.BLUE]: {
        colors: {
            primary: '#2567EF',
            secondary: '#1D1D1B',
        }
    },
};

const ALLOWED_ACCENT_COLORS = Object.values(ACCENT_COLORS);

export const isAllowedTrainingAccentColor = accentColor => ALLOWED_ACCENT_COLORS.includes(accentColor);
export const getTrainingAccentColors = accentColor => ACCENT_COLORS_DATA[accentColor]?.colors ?? ACCENT_COLORS_DATA[ACCENT_COLORS.BLUE].colors;
